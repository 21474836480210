import React, { Component } from 'react';

import { Accordion, Image } from 'library';
import { location_to_url } from 'functions';

export default class WikiPageContent extends Component {
    render() {
        let pretty_info = [];

        let index = 0;
        for (let section of this.props.data) {
            let section_jsx = [];
            for (let subsection of section['subsections']) {
                if (subsection['name']) {
                    section_jsx.push(<h5>{subsection['name']}</h5>);
                }
                for (let item of subsection['content']) {
                    item = item.replaceAll("volo's_rating", 'volos_rating');
                    section_jsx.push(
                        <div
                            dangerouslySetInnerHTML={{
                                __html: item,
                            }}
                        ></div>,
                    );
                }
            }

            if (section['name']) {
                pretty_info.push(
                    <Accordion index={index} name={section['name']}>
                        {section_jsx}
                    </Accordion>,
                );
            } else {
                pretty_info.push(
                    <Accordion index={index} name={'General Information'}>
                        {section_jsx}
                    </Accordion>,
                );
            }

            index += 1;
        }

        let image = null;
        if (this.props.image) {
            image = (
                <div>
                    <Image
                        src={this.props.image}
                        style={{
                            width: '100%',
                            marginBottom: '10px',
                            borderRadius: '3px',
                        }}
                    />
                    <div>{this.props.image_text}</div>
                </div>
            );
        }

        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h3 style={{ marginBottom: '16px' }}>
                            <a
                                href={
                                    'https://forgottenrealms.fandom.com/wiki/' +
                                    this.props.name.replace("'", '%27')
                                }
                                target="_blank"
                            >
                                {this.props.name}
                            </a>
                        </h3>
                    </div>
                    <div className="col-12">{image}</div>
                </div>
                {pretty_info}
            </div>
        );
    }
}
