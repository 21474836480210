import React, { Component } from 'react';

import { Alert, Button } from 'library';

export default class CreatorCredit extends Component {
    render() {
        if (this.props.location.map_maker_name) {
            return (
                <div
                    style={{
                        position: 'absolute',
                        top: '0px',
                        width: '100%',
                    }}
                >
                    <Alert
                        type="info"
                        style={{
                            margin: '0px auto',
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                            width: 'fit-content',
                            padding: '10px 20px',
                        }}
                    >
                        {'This map was created by '}
                        <b>{this.props.location.map_maker_name}</b>
                        <Button
                            href={this.props.location.map_maker_url}
                            rel="noreferrer"
                            target="_blank"
                            type="outline-dark"
                            style={{
                                marginLeft: '15px',
                                verticalAlign: 'baseline',
                            }}
                        >
                            {'Support Them!'}
                        </Button>
                    </Alert>
                </div>
            );
        } else {
            return null;
        }
    }
}
