import React, { Component } from 'react';

import { Button, Form, TextArea } from 'library';
import { ajax_wrapper, location_to_url } from 'functions';

export default class CampaignNotes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            campaigns: [],
            campaign: {},
            notes: [],
            dm_note: {},
            note_saved: false,
            loaded: false,
        };

        this.campaigns_callback = this.campaigns_callback.bind(this);
        this.campaign_callback = this.campaign_callback.bind(this);
        this.note_callback = this.note_callback.bind(this);
        this.save_note = this.save_note.bind(this);
        this.save_callback = this.save_callback.bind(this);
    }

    componentDidMount() {
        let user = window.secret_react_state.user;
        //if logged in and campaign chosen
        if (user && user.id && this.props.campaign_id) {
            ajax_wrapper(
                'GET',
                `/api/home/campaign/${this.props.campaign_id}/`,
                {},
                this.campaign_callback,
            );
        } else if (user && user.id) {
            //if logged in and no campaign chosen
            ajax_wrapper(
                'GET',
                `/api/home/campaign/list/?owner=${user.id}`,
                {},
                this.campaigns_callback,
            );
        } else {
            //else
            this.setState({ loaded: true });
        }
    }

    campaigns_callback(data) {
        this.setState({ campaigns: data, loaded: true });
    }

    campaign_callback(data) {
        console.log('Campaign Callback', data);
        ajax_wrapper(
            'GET',
            `/api/home/campaignnote/list/?campaign_id=${this.props.campaign_id}&location_id=${this.props.page_location.location_id}&only_fields=note,id,user`,
            {},
            this.note_callback,
        );

        this.setState({ campaign: data, loaded: true });
    }

    note_callback(data) {
        console.log('Note Callback', data);
        this.setState({ dm_note: data[0] });
    }

    save_note(data, callback) {
        let user = window.secret_react_state.user;

        if (this.state.dm_note && this.state.dm_note.id) {
            ajax_wrapper(
                'POST',
                '/api/home/campaignnote/${this.state.dm_note.id}/',
                data,
                this.save_callback,
            );
        } else {
            data['campaign'] = this.props.campaign_id;
            data['user'] = user.id;
            data['location'] = this.props.page_location.location_id;
            ajax_wrapper(
                'POST',
                '/api/home/campaignnote/',
                data,
                this.save_callback,
            );
        }

        callback();
    }

    save_callback(data) {
        this.setState({ dm_note: data, note_saved: true });
    }

    render() {
        let user = window.secret_react_state.user;
        let notes = null;

        //not logged in
        if (!user) {
            notes = (
                <div>
                    <p>
                        This is a feature to add custom notes for your own
                        Campaign.
                    </p>
                    <h6>Available Now</h6>
                    <ul>
                        <li>
                            Take notes on specific locations that only you as
                            the DM can see.
                        </li>
                    </ul>

                    <h6>Coming Soon</h6>
                    <ul>
                        <li>
                            Player Maps, no location info until you toggle a
                            location on.
                        </li>
                        <li>
                            Show players either only just the name, notes you
                            want to share with them or the wiki entry + notes.
                        </li>
                        <li>
                            Player notes: Players can take notes on locations
                            shared with them that the DM also has access to.
                        </li>
                    </ul>

                    <p>
                        {' '}
                        Only available for signed in users.{' '}
                        <Button href="/signup/" type="success">
                            Sign Up Now!
                        </Button>
                    </p>
                </div>
            );
        } else if (user && !this.state.campaign.id) {
            //logged in, no campaigns
            if (this.state.campaigns.length == 0) {
                notes = (
                    <div>
                        <p>
                            This is a feature to add custom notes for your own
                            Campaign. However, you haven't made a campaign yet,{' '}
                            <a href="/campaigns/">make one here.</a>
                        </p>
                    </div>
                );
            } else {
                //logged in, has campaigns, none chosen.
                let campaign_list = [];
                for (var campaign of this.state.campaigns) {
                    campaign_list.push(
                        <li>
                            <a
                                href={location_to_url(
                                    this.props.page_location.name,
                                    campaign.id,
                                )}
                            >
                                {campaign.name}
                            </a>
                        </li>,
                    );
                }

                notes = (
                    <div>
                        <p>
                            This is a feature to add custom notes for your own
                            Campaign. Choose which campaign below or{' '}
                            <a href="/campaigns/">make a new one here.</a>
                        </p>
                        <ul>{campaign_list}</ul>
                    </div>
                );
            }
        } else if (user && this.state.campaign.id) {
            //logged in and selected campaign
            let key = this.props.page_location.location_id;
            if (this.state.dm_note && this.state.dm_note.id) {
                key = this.state.dm_note.id;
            }

            notes = (
                <div>
                    <h4>Campaign: {this.state.campaign.name}</h4>
                    <h5>Location: {this.props.page_location.name}</h5>
                    <Form
                        submit={this.save_note}
                        defaults={this.state.dm_note}
                        key={key}
                    >
                        <TextArea name="note" />
                    </Form>
                </div>
            );
        }

        return notes;
    }
}
