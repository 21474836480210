import React, { Component } from 'react';
import { Loading, Form, TextInput, Button, Alert, Container } from 'library';
import { ajax_wrapper, location_to_url } from 'functions';

export default class EditCampaign extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            name: '',
            error: '',
            success: false,
        };

        this.get_campaign = this.get_campaign.bind(this);
        this.save_campaign = this.save_campaign.bind(this);
        this.callback = this.callback.bind(this);
    }

    componentDidMount() {
        if (this.props.id) {
            let url = '/api/home/campaign/' + this.props.id;

            ajax_wrapper('GET', url, {}, this.get_campaign);
        } else {
            this.setState({ loaded: true });
        }
    }

    get_campaign(data) {
        data['loaded'] = true;
        this.setState(data);
    }

    save_campaign(data) {
        console.log('Save Campaign');
        let url = '/api/home/campaign/';
        if (this.props.id) {
            url = `/api/home/campaign/{this.props.id}`;
        }
        console.log('Url', url);
        data['owner'] = window.secret_react_state.user.id;
        console.log('Data', data);

        ajax_wrapper('POST', url, data, this.callback);
    }

    callback(data) {
        if (this.props.id) {
            this.setState({ success: true });
        } else {
            window.location.href = '/campaigns/';
        }
    }

    render() {
        let success = null;
        if (this.state.success) {
            success = <Alert type="success" text="Saved" />;
        }
        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    <h2>Edit Campaign</h2>

                    <Form submit={this.save_campaign}>
                        <TextInput name="name" label="Name" />
                    </Form>
                    {success}
                </Container>
            </Loading>
        );
    }
}
