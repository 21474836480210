import React, { Component } from 'react';
import { Loading, Form, TextInput, Button } from 'library';
import { ajax_wrapper, location_to_url } from 'functions';

export default class CampaignList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            campaigns: [],
        };

        this.get_campaigns = this.get_campaigns.bind(this);
        this.query_campaigns = this.query_campaigns.bind(this);
    }

    componentDidMount() {
        this.query_campaigns();
    }

    query_campaigns() {
        let url = `/api/home/campaign/list/?owner=${window.secret_react_state.user.id}`;

        ajax_wrapper('GET', url, {}, this.get_campaigns);
    }

    get_campaigns(data) {
        if (!Array.isArray(data)) {
            data = [];
        }
        this.setState({ campaigns: data, loaded: true });
    }

    render() {
        let campaign_list = [];
        for (let campaign of this.state.campaigns) {
            campaign_list.push(
                <tr>
                    <td>{campaign.name}</td>
                    <td>
                        <a href={`/campaign/${campaign.id}/sword_coast/`}>
                            Venture Forth
                        </a>
                    </td>
                    <td>
                        <a href={`/edit_campaign/${campaign.id}/`}>
                            Edit Campaign
                        </a>
                    </td>
                </tr>,
            );
        }

        return (
            <Loading loaded={this.state.loaded}>
                <div className="container">
                    <h2>Campaign List</h2>
                    <Button type="primary" href="/edit_campaign/">
                        Add New Campaign
                    </Button>

                    <table className="table" key={this.state.num_parents}>
                        <thead>
                            <tr>
                                <th>Campaign Name</th>
                                <th>Venture There</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>{campaign_list}</tbody>
                    </table>
                </div>
            </Loading>
        );
    }
}
