import React, { Component } from 'react';
import { Stage, Layer, Group } from 'react-konva';

import { CanvasImage } from 'components';
import { get_position_on_map } from 'functions';
import ZoomButtons from './zoom_buttons.js';

const ZOOM_MULTIPLIER = 1.1;

function get_distance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

function get_center(p1, p2) {
    return [(p1.x + p2.x) / 2, (p1.y + p2.y) / 2];
}

export default class CanvasMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            draggable: true,
            zoom: this.props.zoom || 0.5,
            position: [0, 0],
            image_size: [0, 0],

            pinch_zoom_distance: null,
        };

        this.on_drag_end = this.on_drag_end.bind(this);
        this.on_mouse_move = this.on_mouse_move.bind(this);

        this.handle_key_down = this.handle_key_down.bind(this);
        this.handle_scroll = this.handle_scroll.bind(this);
        this.handle_touchmove = this.handle_touchmove.bind(this);

        this.zoom_in = this.zoom_in.bind(this);
        this.zoom_out = this.zoom_out.bind(this);

        this.handle_map_click = this.handle_map_click.bind(this);
    }

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {}

    handle_map_image_load(img) {
        let width = this.props.width;
        let height = this.props.height;

        let width_ratio = img.width / width;
        let height_ratio = img.height / height;

        let zoom = 1;
        if (width_ratio > height_ratio) {
            zoom = 1 / width_ratio;
        } else {
            zoom = 1 / height_ratio;
        }

        let offset = (width - img.width * zoom) / 2;
        let position = [offset, 0];

        this.setState(
            {
                image_size: [img.width, img.height],
                zoom: zoom,
                position: position,
            },
            this.props.handle_map_image_load(img, zoom, position),
        );
    }

    on_drag_end(event) {
        var position = [
            event.currentTarget.attrs.x,
            event.currentTarget.attrs.y,
        ];

        position = this.check_and_fix_bounds(position);

        this.setState({ position: position }, () =>
            this.props.handle_drag_end(position),
        );
    }

    on_mouse_move(event) {
        let position = get_position_on_map(
            [event.evt.clientX, event.evt.clientY],
            this.state.position,
            this.state.zoom,
        );

        //console.log('Mouse On Map', position);
    }

    handle_map_click(event) {
        var position = get_position_on_map(
            [event.evt.clientX, event.evt.clientY],
            this.state.position,
            this.state.zoom,
        );
        this.props.handle_map_click(event, position);
    }

    check_and_fix_bounds(position) {
        var window_width = this.props.width;
        let window_height = this.props.height;

        var map_width = this.state.image_size[0];
        var map_height = this.state.image_size[1];

        var out_of_bounds = false;
        if (position[0] >= window_width - 30) {
            position[0] = window_width - 60;
            out_of_bounds = true;
        }
        if (position[0] <= -1 * map_width * this.state.zoom + 30) {
            position[0] = -1 * map_width * this.state.zoom + 60;
            out_of_bounds = true;
        }
        if (position[1] >= window_height - 30) {
            position[1] = window_height - 60;
            out_of_bounds = true;
        }
        if (position[1] <= -1 * map_height * this.state.zoom + 30) {
            position[1] = -1 * map_height * this.state.zoom + 60;
            out_of_bounds = true;
        }

        if (out_of_bounds) {
            this.layer_node.absolutePosition({
                x: position[0],
                y: position[1],
            });
        }

        return position;
    }

    handle_key_down(event) {
        if (this.props.handle_key_down) {
            if (!this.props.handle_key_down(event)) {
                return false;
            }
        }

        var mouse_position = [this.props.width / 2, this.props.height / 2];

        if (event.key === '=' || event.key === '+') {
            this.zoom_in(mouse_position);
        } else if (event.key === '-' || event.key === '_') {
            this.zoom_out(mouse_position);
        }
    }

    handle_scroll(event) {
        if (this.props.handle_scroll) {
            if (!this.props.handle_scroll(event)) {
                return false;
            }
        }

        //var delta = e.nativeEvent.wheelDelta;
        var delta = event.deltaY;
        var mouse_position = [event.clientX, event.clientY];

        if (delta < 0) {
            this.zoom_in(mouse_position);
        } else {
            this.zoom_out(mouse_position);
        }
    }

    zoom_in(mouse_position) {
        let zoom = this.state.zoom;
        let original_zoom = zoom;
        zoom *= ZOOM_MULTIPLIER;

        let position_change = this.calculate_position_change_from_mouse(
            mouse_position,
            original_zoom,
            zoom,
        );
        let position = [
            this.state.position[0] + position_change[0],
            this.state.position[1] + position_change[1],
        ];
        //position = this.check_and_fix_bounds(position);

        this.setState(
            {
                zoom: zoom,
                position: position,
            },
            () => this.props.handle_zoom(zoom, position),
        );
    }

    zoom_out(mouse_position) {
        let zoom = this.state.zoom;
        let original_zoom = zoom;
        zoom /= ZOOM_MULTIPLIER;

        let position_change = this.calculate_position_change_from_mouse(
            mouse_position,
            original_zoom,
            zoom,
        );

        let position = [
            this.state.position[0] + position_change[0],
            this.state.position[1] + position_change[1],
        ];
        //position = this.check_and_fix_bounds(position);

        this.setState(
            {
                zoom: zoom,
                position: position,
            },
            () => this.props.handle_zoom(zoom, position),
        );
    }

    calculate_position_change_from_mouse(mouse_position, start_zoom, end_zoom) {
        var start_position = get_position_on_map(
            mouse_position,
            this.state.position,
            start_zoom,
        );

        var end_position = get_position_on_map(
            mouse_position,
            this.state.position,
            end_zoom,
        );

        let position_change = [
            (end_position[0] - start_position[0]) * end_zoom,
            (end_position[1] - start_position[1]) * end_zoom,
        ];

        return position_change;
    }

    handle_touchmove(event) {
        event.evt.preventDefault();
        event.cancelBubble = true;

        var touch1 = event.evt.touches[0];
        var touch2 = event.evt.touches[1];

        // we need to restore dragging, if it was cancelled by multi-touch
        if (touch1 && !touch2 && !this.state.draggable) {
            this.setState({
                draggable: true,
                pinch_zoom_distance: null,
            });
        }

        if (touch1 && touch2) {
            // if the stage was under Konva's drag&drop
            // we need to stop it, and implement our own pan logic with two pointers
            if (this.state.draggable) {
                this.setState({ draggable: false });
            }

            var p1 = {
                x: touch1.clientX,
                y: touch1.clientY,
            };
            var p2 = {
                x: touch2.clientX,
                y: touch2.clientY,
            };

            var new_center = get_center(p1, p2);
            var dist = get_distance(p1, p2);

            let last_dist = this.state.pinch_zoom_distance;
            if (!last_dist) {
                last_dist = dist;
            }

            var new_zoom = this.state.zoom * (dist / last_dist);

            let position_change = this.calculate_position_change_from_mouse(
                new_center,
                this.state.zoom,
                new_zoom,
            );

            let new_position = [
                this.state.position[0] + position_change[0],
                this.state.position[1] + position_change[1],
            ];

            this.setState(
                {
                    position: new_position,
                    zoom: new_zoom,
                    pinch_zoom_distance: dist,
                },
                () => this.props.handle_zoom(new_zoom, new_position),
            );
        }
    }

    render() {
        return (
            <div
                onWheel={this.handle_scroll}
                onKeyDown={this.handle_key_down}
                onKeyUp={this.props.handle_key_up}
                tabIndex="0"
                style={{
                    outline: 'none',
                }}
            >
                <ZoomButtons
                    mouse_position={[
                        this.props.width / 2,
                        this.props.height / 2,
                    ]}
                    zoom_in={this.zoom_in}
                    zoom_out={this.zoom_out}
                />
                <Stage
                    onTouchMove={this.handle_touchmove}
                    onTouchEnd={() =>
                        this.setState({
                            draggable: true,
                            pinch_zoom_distance: null,
                        })
                    }
                    width={this.props.width}
                    height={this.props.height}
                >
                    <Layer
                        ref={(node) => {
                            this.layer_node = node;
                        }}
                        draggable={this.state.draggable}
                        onDragEnd={this.on_drag_end}
                        onMouseMove={this.on_mouse_move}
                        onClick={this.handle_map_click}
                        x={this.state.position[0]}
                        y={this.state.position[1]}
                    >
                        <Group
                            key={'map_group'}
                            scale={{
                                x: this.state.zoom,
                                y: this.state.zoom,
                            }}
                        >
                            <CanvasImage
                                src={this.props.location.image}
                                on_load={(img) =>
                                    this.setState(
                                        {
                                            image_size: [img.width, img.height],
                                        },
                                        () => this.handle_map_image_load(img),
                                    )
                                }
                            />
                        </Group>

                        {this.props.children}
                    </Layer>
                </Stage>
            </div>
        );
    }
}
